import Head from 'next/head'

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="shortcut icon" href="https://explorer.walkmining.com/favicon.png" />
        <link rel="shortcut icon" type="image/x-icon" href="https://explorer.walkmining.com/favicon.ico" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  )
}

Meta.defaultProps = {
  title: 'WalkMining Explorer - A bridge between health and finance Converts your steps to rewards!',
  keyword: 'wkm, wkg, blockchain, cryptocurrency, NFT, wallet, M2E, Health, move-to-earn, walk',
  desc: 'A bridge between health and finance Converts your steps to rewards!',
}

export default Meta
