import { useEffect, useState, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from 'next/router'
import Link from 'next/link'
import debounce from 'lodash/debounce'
import { useRecoilState } from 'recoil'
import Cookies from 'js-cookie'
import DarkMode from '../mode/DarkMode'
import { isChildrenPageActive, isParentPageActive } from '../../utils/daynamicNavigation'
import { getSearchResultLinkPath } from '../../utils/utils'
import { fetchSearch } from '../../utils/client/search-api'
import Loading from '../Loading'
import { getAvatar } from '../../utils/avatar'
import {
  userNameState,
  userEmailState,
  userProfileImageState,
  userAccessTokenState,
  userReferralCodeState,
  KEY_USER_ACCESSTOKEN_COOKIE,
  KEY_USER_EMAIL_COOKIE,
  KEY_USER_NAME_COOKIE,
  KEY_USER_PROFILE_IMAGE_COOKIE,
  KEY_USER_REFERRAL_CODE_COOKIE,
} from '../../utils/states'
import { notify } from '../../utils/notifications'

/* -------------------------------------------------------------------------- */
/*                            daynamic navigations                            */
/* -------------------------------------------------------------------------- */
const ranking = {
  id: 1,
  name: 'Ranking',
  pages: [
    {
      id: uuidv4(),
      name: 'Top Miner',
      path: '/ranking?type=mining',
      condition: false,
      isHot: true,
      isOnSale: false,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          className="h-4 w-4 fill-jacarta-700"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M22 12.999V20a1 1 0 0 1-1 1h-8v-8.001h9zm-11 0V21H3a1 1 0 0 1-1-1v-7.001h9zM11 3v7.999H2V4a1 1 0 0 1 1-1h8zm10 0a1 1 0 0 1 1 1v6.999h-9V3h8z" />
        </svg>
      ),
    },
    {
      id: uuidv4(),
      name: 'Top Gamer',
      path: '/ranking?type=game',
      condition: false,
      isHot: true,
      isOnSale: false,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          className="h-4 w-4 fill-[#428AF8]"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M2 6c0-.552.455-1 .992-1h18.016c.548 0 .992.445.992 1v14c0 .552-.455 1-.992 1H2.992A.994.994 0 0 1 2 20V6zm2 1v12h16V7H4zm10 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 2a5 5 0 1 1 0-10 5 5 0 0 1 0 10zM4 2h6v2H4V2z" />
        </svg>
      ),
    },
    {
      id: uuidv4(),
      name: 'Top User',
      path: '/ranking?type=user',
      condition: false,
      isHot: true,
      isOnSale: false,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          className="h-4 w-4 fill-[#428AF8]"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M2 6c0-.552.455-1 .992-1h18.016c.548 0 .992.445.992 1v14c0 .552-.455 1-.992 1H2.992A.994.994 0 0 1 2 20V6zm2 1v12h16V7H4zm10 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 2a5 5 0 1 1 0-10 5 5 0 0 1 0 10zM4 2h6v2H4V2z" />
        </svg>
      ),
    },
  ],
}

export default function Header() {
  const [toggle, setToggle] = useState(false)
  const [isCollapse, setCollapse] = useState(null)
  const [inputSearchValue, setInputSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [viewSearchResultModal, setViewSearchResultModal] = useState(false)

  const [csrUserName, setCsrUserName] = useState(undefined)
  const [csrUserEmail, setCsrUserEmail] = useState(undefined)
  const [csrUserProfileImage, setCsrUserProfileImage] = useState(undefined)
  const [csrUserAccessToken, setCsrUserAccessToken] = useState(undefined)
  const [csrUserReferralCode, setCsrUserReferralCode] = useState(undefined)

  const [userName, setUserNameState] = useRecoilState(userNameState)
  const [userEmail, setUserEmailState] = useRecoilState(userEmailState)
  const [userProfileImage, setUserProfileImageState] = useRecoilState(userProfileImageState)
  const [userReferralCode, setUserReferralCodeState] = useRecoilState(userReferralCodeState)
  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState)

  const [copied, setCopied] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }, [copied])

  // window resize
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        setToggle(false)
      }
    })
  })

  useEffect(() => {
    setCsrUserName(userName)
    setCsrUserEmail(userEmail)
    setCsrUserAccessToken(userAccessToken)
    setCsrUserProfileImage(userProfileImage)
    setCsrUserReferralCode(userReferralCode)
  }, [userName, userEmail, userAccessToken, userProfileImage, userReferralCode])

  const route = useRouter()

  const handleInputSearchValue = (e) => {
    if (!e || e.trim().length === 0) {
      setSearchResult([])
    } else {
      debounceSearch(e)
    }
    setInputSearchValue(e)
  }

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      search(inputSearchValue)
    }
  }

  async function search(q) {
    setIsSearchLoading(true)
    const response = await fetchSearch(q)
    if (response && response.status === 200 && response.data) {
      setViewSearchResultModal(true)
      setSearchResult(response.data)
    } else {
      setSearchResult([])
    }
    setIsSearchLoading(false)
  }

  const debounceSearch = useMemo(
    () =>
      debounce((e) => {
        search(e)
      }, 500),
    [],
  )

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null)
    }
    setCollapse(id)
  }

  function logout() {
    setUserNameState(undefined)
    setUserEmailState(undefined)
    setUserAccessToken(undefined)
    setUserProfileImageState(undefined)
    setUserReferralCodeState(undefined)

    setCsrUserAccessToken(undefined)
    setCsrUserEmail(undefined)
    setCsrUserName(undefined)
    setCsrUserProfileImage(undefined)
    setCsrUserReferralCode(undefined)

    Cookies.remove(KEY_USER_ACCESSTOKEN_COOKIE)
    Cookies.remove(KEY_USER_EMAIL_COOKIE)
    Cookies.remove(KEY_USER_NAME_COOKIE)
    Cookies.remove(KEY_USER_PROFILE_IMAGE_COOKIE)
    Cookies.remove(KEY_USER_REFERRAL_CODE_COOKIE)

    notify({
      type: 'info',
      message: 'Sign out',
    })

    if (window) {
      window.location.reload()
    }
  }

  return (
    <>
      {/* main desktop menu sart*/}
      <header className="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors">
        <div className="flex items-center px-6 py-6 xl:px-24 ">
          <Link legacyBehavior className="shrink-0" href="/">
            <a>
              <div className="dark:hidden">
                <img src={`/images/logo.png`} alt="WalkMining Explorer" className="max-h-10 h-auto " />
              </div>
              <div className="hidden dark:block">
                <img src={`/images/logo_white.png`} alt="WalkMining Explorer" className="max-h-10 h-auto " />
              </div>
            </a>
          </Link>
          {/* End  logo */}

          {/* <!-- Search --> */}
          {route.pathname !== '/' && (
            <div className="relative ml-12 mr-8 hidden basis-3/12 lg:block xl:ml-[8%]">
              <input
                type="search"
                className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                placeholder="Search"
                value={inputSearchValue}
                onChange={(e) => {
                  handleInputSearchValue(e.target.value)
                }}
                onKeyUp={handleSearchKeyPress}
              />
              <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="fill-jacarta-500 h-4 w-4 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
                </svg>
              </span>
              {isSearchLoading && (
                <span className="absolute right-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
                  <Loading />
                </span>
              )}
            </div>
          )}
          {/* <!-- Search End --> */}

          <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
            <nav className="navbar w-full">
              <ul className="flex flex-col lg:flex-row">
                {/* Hone */}
                <li className="group">
                  <Link legacyBehavior href="/">
                    <a>
                      <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                        <span className={isChildrenPageActive(route.asPath, '/') ? 'text-accent dark:text-accent' : ''}>
                          Home
                        </span>
                      </button>
                    </a>
                  </Link>
                </li>

                <li className="group">
                  <Link legacyBehavior href="/staking">
                    <a>
                      <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                        <span
                          className={
                            isChildrenPageActive(route.asPath, '/staking') ? 'text-accent dark:text-accent' : ''
                          }
                        >
                          Staking
                          <span className="text-[10px] text-jacarta-600 bg-orange/40 dark:text-orange rounded-full px-1 py-[2px] ml-1">
                            Beta
                          </span>
                        </span>
                      </button>
                    </a>
                  </Link>
                </li>

                {/* store */}
                {/* <li className="js-nav-dropdown group relative">
                  <span className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full cursor-pointer">
                    <Link legacyBehavior href={`/shop`}>
                      <span className={route.pathname.includes('/shop') ? 'text-accent dark:text-accent' : ''}>
                        Shop
                      </span>
                    </Link>
                  </span>
                </li> */}

                {/* ranking */}
                <li className="js-nav-dropdown group relative">
                  <button className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full">
                    <span className={route.pathname === '/ranking' ? 'text-accent dark:text-accent' : ''}>Ranking</span>
                    <i className="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </button>
                  <ul
                    className="dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative"
                    aria-labelledby="navDropdown-4"
                  >
                    {ranking?.pages?.map?.((page) => (
                      <li key={page.id}>
                        <Link legacyBehavior href={page?.path}>
                          <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors">
                            <span
                              className={`font-display ${
                                isChildrenPageActive(page.path, route.asPath)
                                  ? 'text-accent dark:text-accent'
                                  : 'text-jacarta-700'
                              } text-sm dark:text-white`}
                            >
                              {page?.name}
                            </span>
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="group">
                  <a href="https://avatar.walkmining.com" target="_blank" rel="noopener noreferrer">
                    <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                      <span>Avatar</span>
                    </button>
                  </a>
                </li>

                <li className="js-nav-dropdown group relative">
                  <button className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full">
                    <a
                      href={`https://www.walkmining.com/invite${
                        route.query.referralCode ? '?id=' + route.query.referralCode : ''
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </a>
                    <i className="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </button>
                </li>

                {/* NFT */}
                {/* <li className="group">
                  <Link legacyBehavior href="/">
                    <a>
                      <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                        <span
                          className={isChildrenPageActive(route.asPath, '/nft') ? 'text-accent dark:text-accent' : ''}
                        >
                          NFT
                        </span>
                      </button>
                    </a>
                  </Link>
                </li> */}
              </ul>
            </nav>
            {/* End menu for desktop */}

            <div className="ml-8 hidden items-center lg:flex xl:ml-12">
              {!csrUserAccessToken && (
                <Link legacyBehavior href={`/login?ref=${route.asPath}`}>
                  <div className="js-nav-dropdown group-dropdown relative">
                    <button className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                      </svg>
                    </button>
                  </div>
                </Link>
              )}
              {csrUserName && csrUserEmail && csrUserAccessToken && (
                <div className="js-nav-dropdown group-dropdown relative">
                  <button className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
                    <img className="w-9 h-9" src={getAvatar(csrUserProfileImage || '1')} />
                  </button>
                  <div className="dropdown-menu dark:bg-jacarta-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0">
                    <div className="font-display text-jacarta-700 my-4 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white">
                      <span>{csrUserEmail}</span>
                    </div>
                    <div className="cursor-pointer flex justify-center mb-3">
                      <div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 inline-flex items-center justify-center rounded-full border bg-white py-1.5 px-4">
                        <Link
                          legacyBehavior
                          href={`/user/${csrUserReferralCode}`}
                          className="cursor-pointer js-copy-clipboard dark:text-jacarta-200 max-w-[10rem] select-none overflow-hidden text-ellipsis whitespace-nowrap"
                        >
                          <span>{csrUserReferralCode}</span>
                        </Link>
                      </div>
                    </div>
                    <Link legacyBehavior href="/my">
                      <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width={24}
                          height={24}
                          className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                        </svg>
                        <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">My Profile</span>
                      </a>
                    </Link>
                    {/* <Link legacyBehavior href="/my?tab=REWARD">
                      <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
                        <span className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white">🎁</span>
                        <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">My Reward</span>
                      </a>
                    </Link> */}
                    <div className="cursor-pointer" onClick={logout}>
                      <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width={24}
                          height={24}
                          className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z" />
                        </svg>
                        <span className="font-display text-[#ff4646d2] mt-1 text-sm dark:text-white">Sign out</span>
                      </a>
                    </div>
                  </div>
                </div>
              )}
              <DarkMode />
            </div>
            {/* End header right content (metamask and other) for desktop */}
          </div>
          {/* header menu conent end for desktop */}

          <div className="ml-auto flex lg:hidden">
            <Link legacyBehavior href={csrUserAccessToken ? '/my' : `/login?ref=${route.asPath}`}>
              <a
                className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                aria-label="profile"
              >
                {csrUserAccessToken ? (
                  <img className="w-9 h-9" src={getAvatar(csrUserProfileImage || '1')} />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                  </svg>
                )}
              </a>
            </Link>
            <DarkMode />
            <button
              className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              aria-label="open mobile menu"
              onClick={() => setToggle(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
          {/* End header right content  for mobile */}
        </div>
        {/* End flex item */}
      </header>
      {/* main desktop menu end */}

      {/* start mobile menu and it's other materials  */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? 'nav-menu--is-open' : 'hidden'
        }`}
      >
        <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
          <div className="dark:hidden">
            <img
              src={`/images/logo.png`}
              alt="WalkMining Explorer - A bridge between health and finance."
              className="max-h-10 h-auto"
            />
          </div>

          <div className="hidden dark:block">
            <img
              src={`/images/logo_white.png`}
              alt="WalkMining Explorer - A bridge between health and finance."
              className="max-h-10 h-auto"
            />
          </div>

          <button
            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
        {/* mobile menu top header content */}

        <div action="search" className="relative mt-24 w-full lg:hidden">
          <input
            type="search"
            className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-3 px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
            placeholder="Search"
            value={inputSearchValue}
            onChange={(e) => {
              handleInputSearchValue(e.target.value)
            }}
            onKeyUp={handleSearchKeyPress}
          />
          <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-500 h-4 w-4 dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
            </svg>
          </span>
          {isSearchLoading && (
            <span className="absolute right-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
              <Loading />
            </span>
          )}
        </div>

        <nav className="navbar w-full mt-6">
          <ul className="flex flex-col lg:flex-row">
            <li className="group" onClick={() => setToggle(false)}>
              <Link legacyBehavior href="/">
                <a>
                  <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span className={isChildrenPageActive('/', route.asPath) ? 'text-accent dark:text-accent' : ''}>
                      Home
                    </span>
                  </button>
                </a>
              </Link>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <Link legacyBehavior href="/staking">
                <a>
                  <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={isChildrenPageActive('/staking', route.asPath) ? 'text-accent dark:text-accent' : ''}
                    >
                      Staking
                      <span className="rounded bg-blue py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                        Beta
                      </span>
                    </span>
                  </button>
                </a>
              </Link>
            </li>
            {/* <li className="group" onClick={() => setToggle(false)}>
              <Link legacyBehavior href="/shop">
                <a>
                  <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span className={isChildrenPageActive('/shop', route.asPath) ? 'text-accent dark:text-accent' : ''}>
                      Shop
                    </span>
                  </button>
                </a>
              </Link>
            </li> */}
            <li className="js-nav-dropdown group relative">
              <button
                onClick={() => mobileCollapse(ranking.id)}
                className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
              >
                <span className={isParentPageActive(ranking.pages, route.asPath) ? 'text-accent dark:text-accent' : ''}>
                  {ranking.name}
                </span>
                <i className="lg:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="h-4 w-4 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </i>
              </button>
              <ul
                className={`dropdown-menu left-0 top-[85%] z-10 grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${
                  isCollapse === ranking.id ? 'block' : 'hidden'
                }`}
              >
                {ranking?.pages?.map((page) => (
                  <li key={page.id} onClick={() => setToggle(false)}>
                    <Link legacyBehavior href={page.path}>
                      <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors justify-between">
                        <span
                          className={
                            isChildrenPageActive(page.path, route.asPath) ? 'text-accent dark:text-accent' : ''
                          }
                        >
                          {page.name}
                        </span>
                        {page.condition ? (
                          <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                            new
                          </span>
                        ) : undefined}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <a href="https://avatar.walkmining.com" target="_blank" rel="noopener noreferrer">
                <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                  <span>Avatar</span>
                  {/* <span className="text-[10px] text-jacarta-600 bg-orange/40 dark:text-orange rounded-full px-1 py-[2px] ml-1">
                    New
                  </span> */}
                </button>
              </a>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <a
                className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5"
                href={`https://www.walkmining.com/invite${
                  route.query.referralCode ? '?id=' + route.query.referralCode : ''
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Download</span>
              </a>
            </li>
            {userAccessToken && csrUserAccessToken && (
              <li className="group" onClick={() => setToggle(false)}>
                <span
                  className="text-[#ff4646d2] font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 cursor-pointer"
                  onClick={logout}
                >
                  <span>Sign out</span>
                </span>
              </li>
            )}
          </ul>
        </nav>
        {/* End navbar mobile menu  */}

        <div className="mt-10 w-full lg:hidden">
          <hr className="dark:bg-jacarta-600 bg-jacarta-100 my-5 h-px border-0" />
          <div className="flex items-center justify-center space-x-5">
            <a className="group" href="https://twitter.com/Walkmining" target="_blank" rel="noopener noreferrer">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="twitter"
                className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg>
            </a>
            <a className="group" href="https://discord.com/invite/feuc2PR78g" target="_blank" rel="noopener noreferrer">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="discord"
                className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z" />
              </svg>
            </a>
            <a className="group" href="https://www.instagram.com/walkmining/" target="_blank" rel="noopener noreferrer">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="instagram"
                className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
            </a>
          </div>
        </div>
        {/* mt-10 w-full lg:hidden */}
      </div>
      {/* End mobile menu and it's other materials */}

      <div
        className={
          inputSearchValue && inputSearchValue.length > 0 && viewSearchResultModal
            ? 'modal lightbox fade show block'
            : 'modal lightbox fade'
        }
      >
        <div className="modal-dialog modal-dialog-centered modal-xl w-full">
          <div className="modal-content border-0 bg-transparent">
            <div className="modal-body p-0 relative">
              <button
                type="button"
                onClick={() => {
                  setViewSearchResultModal(false)
                  setInputSearchValue('')
                }}
                className="btn-close position-absolute top-0 end-0 p-3 z-10"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#fff" className="h-6 w-6">
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z"></path>
                </svg>
              </button>
              <div id="lightboxCarousel-d7ewe4ig" className="lightbox-carousel carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="position-absolute top-50 start-50 translate-middle text-white">
                      <div className="spinner-border" role="status"></div>
                    </div>
                    <div className="dark:border dark:border-jacarta-600 dark:rounded-2xl">
                      <div className="mt-3 ml-3 text-jacarta-200 text-md h-10">
                        <span className="font-bold text-white">'{inputSearchValue}'</span> Search Result:
                      </div>
                      <div className="overflow-scroll h-[450px] ">
                        {searchResult && searchResult.length > 0 ? (
                          <div>
                            {searchResult.map((item, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="mb-1 border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent"
                                  onClick={() => {
                                    setViewSearchResultModal(false)
                                    setToggle(false)
                                    setInputSearchValue('')
                                  }}
                                >
                                  <div>
                                    <Link
                                      legacyBehavior
                                      href={getSearchResultLinkPath(item.resultType, item.targetValue)}
                                    >
                                      <span className="block cursor-pointer">
                                        {item.resultType === 'USER' && (
                                          <img
                                            className="h-8 w-8 inline-block mr-1"
                                            src={getAvatar(item.profileImage)}
                                          />
                                        )}
                                        <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                                          {item.title}
                                        </span>
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        ) : (
                          <div className="text-center pt-[200px]">Not found result</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
