import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { footerMenuList, socialIcons } from '../data/footer_data'

const footer = () => {
  const { t: tCommon } = useTranslation('common')
  return (
    <>
      {/* <!-- Footer --> */}
      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div className="col-span-3 md:col-span-4">
              {/* <!-- Logo --> */}
              <Link legacyBehavior href="#">
                <a className="mb-6 inline-block">
                  <img
                    src="/images/logo.png"
                    className="max-h-7 dark:hidden"
                    alt="WalkMining Explorer - A bridge between health and finance."
                  />
                </a>
              </Link>

              <Link legacyBehavior href="#">
                <a className=" mb-6 inline-block">
                  <img
                    src="/images/logo_white.png"
                    className="hidden max-h-7 dark:block mb-6"
                    alt={tCommon('description')}
                  />
                </a>
              </Link>
              <p className="dark:text-jacarta-300 mb-12">{tCommon('main.about_title')}</p>

              {/* <!-- Socials --> */}
              <div className="flex space-x-5">
                {socialIcons.map((item) => {
                  const { id, href, text } = item
                  return (
                    <Link legacyBehavior href={href} key={id}>
                      <a target="_blank" rel="noopener noreferrer" className="group cursor-pointer">
                        <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                          <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                        </svg>
                      </a>
                    </Link>
                  )
                })}
              </div>
            </div>

            {footerMenuList.map((single) => (
              <div className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`} key={single.id}>
                <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">{single.title}</h3>
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text } = item
                    return (
                      <li key={id}>
                        <Link legacyBehavior href={href}>
                          <a className="hover:text-accent dark:hover:text-white">{text}</a>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              <span>© {new Date().getFullYear()} WalkMining</span>
            </span>

            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <a
                  className="hover:text-accent dark:hover:text-white"
                  href="https://www.walkmining.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  className="hover:text-accent dark:hover:text-white"
                  href="https://www.walkmining.com/notice?ref=WEB"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notice
                </a>
              </li>
              <li>
                <a
                  className="hover:text-accent dark:hover:text-white"
                  href="https://www.walkmining.com/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and conditions
                </a>
              </li>
              <li>
                <a
                  className="hover:text-accent dark:hover:text-white"
                  href="https://www.walkmining.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}

export default footer
